body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Apercu-Mono;
  @font-face {
    font-family: "Apercu-Mono";
    src: local("Apercu-Mono"), url("./fonts/Apercu-Mono.otf") format("opentype");
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
